import type { FilterTopic } from 'components/FilterMenu/types';

export const updateFilters = (
  facets: Record<string, Record<string, number>>,
  appliedFilters: string[][],
  searchPageLabels: Record<string, string>,
  searchItemLabels: Record<string, string>,
): FilterTopic[] => {
  const difficultLabels: Record<string, string> = {
    0: searchPageLabels?.easy || 'Easy',
    1: searchPageLabels?.intermediate || 'Intermediate',
    2: searchPageLabels?.tough || 'Tough',
  };
  const updatedFilters = Object.keys(facets).map((key: string) => ({
    key,
    label: searchItemLabels[`filter_${key}`] || key,
    options: Object.keys(facets[key] as Record<string, number>).map((opt: string) => ({
      key: `${key}:${opt}`,
      label: key === 'difficulty' ? difficultLabels[opt] : opt,
      selected: appliedFilters.some((appliedFilter) => appliedFilter.indexOf(`${key}:${opt}`) > -1),
      itemCount: facets[key][opt],
    })),
    type: key === 'rating_effective' ? 'rating' : 'text',
  })) as FilterTopic[];
  return updatedFilters;
};
