import { NextRouter } from 'next/router';

/**
 * Handler to redirect on submit within the search overlay to the appropriate search link
 * @param query search term
 * @param rewrite Rewrite object for the search page
 * @param router Next Router
 * TODO: we need to check if the user is already on the search page; we can do a shallow redirect
 */
export const searchSubmitHandler = (
  query: string,
  searchPathTranslation: string,
  router: NextRouter,
): void => {
  router.push({
    pathname: searchPathTranslation,
    query: { query: query || '' },
  });
};
