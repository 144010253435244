import { Grid, GridItem, GridRow } from '../../Grid';
import { Text as Paragraph, Title } from '../../Typography';
import { NextLink } from '../../NextLink';
import { PlainButton } from '../../PlainButton';
import { INotificationBannerProps } from '../index';

export const DesktopContent = ({
  title,
  description,
  link,
}: Pick<INotificationBannerProps, 'title' | 'description' | 'link'>) => {
  return (
    <div className="hidden md:block overflow-hidden">
      <Grid>
        <GridRow columns={16}>
          <GridItem colStart={0} colSpan={4}>
            <Title className="mb-3 px-5" tag="h3" type="xxs">
              {title}
            </Title>
          </GridItem>
          <GridItem colStart={5} colSpan={10}>
            <Paragraph tag="p" type="md" className="px-10">
              {description}
            </Paragraph>
          </GridItem>
          <GridItem colStart={15} colSpan={2}>
            <NextLink
              href={link?.url}
              className="text-black py-4 flex items-center justify-center relative"
              rel="noreferrer"
              target={link?.openInNewTab ? '_blank' : '_self'}
            >
              <PlainButton label={link?.displayName} type="lead" className="text-white" />
            </NextLink>
          </GridItem>
        </GridRow>
      </Grid>
    </div>
  );
};
