import { useEffect, useState } from 'react';
import { DesktopContent } from './components/DesktopContent';
import { MobileContent } from './components/MobileContent';
import { CloseIcon } from '@icons/Close';

export interface INotificationBannerProps {
  id?: string;
  title?: string;
  description?: string;
  link?: ILinkProps;
  active?: boolean;
}

interface ILinkProps {
  url: string;
  displayName: string;
  openInNewTab: boolean;
}

export const NotificationBanner = ({
  id = 'notification-banner',
  title,
  description,
  link,
}: INotificationBannerProps) => {
  const getSessionStorageOrDefault = (key: string, defaultValue: boolean) => {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
      return defaultValue;
    }
    return JSON.parse(stored);
  };

  const [bannerClosed, setBannerClosed] = useState<boolean>(true);

  useEffect(() => {
    setBannerClosed(getSessionStorageOrDefault(`${id}-was-closed`, false));
  }, []);

  useEffect(() => {
    sessionStorage.setItem(`${id}-was-closed`, JSON.stringify(bannerClosed));
  }, [bannerClosed]);

  if (bannerClosed) {
    return null;
  }

  return (
    <div
      className="h-auto w-full overflow-hidden items-center align-middle print:hidden sticky top-[60px] z-[22] mmd:top-[80px]"
      id="banner"
    >
      <button
        type="button"
        onClick={() => {
          setBannerClosed(true);
        }}
        className="absolute z-10 top-10 md:top-12 left-8 md:left-10 w-32"
      >
        <CloseIcon size={28} className="text-white" />
      </button>
      <div className="w-full overflow-hidden bg-bg-red text-white p-10">
        <DesktopContent description={description} title={title} link={link} />
        <MobileContent description={description} title={title} link={link} />
      </div>
    </div>
  );
};
