import { getCountryCodeFromLocale } from './getCountryCodeFromLocale';
import { getDomainConfigByHost } from './getDomainConfigByHost';
import { getDomainConfigByLocale } from './getDomainConfigByLocale';
import { getIsMultiLanguageDomain } from './getIsMultiLanguageDomain';
import { getLanguageFromLocale } from './getLanguageFromLocale';
import { getLocalizedLinkPath } from './getLocalizedLinkPath';
import { getMarket } from './getMarket';
import { getPathTranslations } from './getPathTranslations';

export const LocalizationService = {
  getMarket,
  getLocalizedLinkPath,
  getDomainConfigByLocale,
  getIsMultiLanguageDomain,
  getPathTranslations,
  getDomainConfigByHost,
  getLanguageFromLocale,
  getCountryCodeFromLocale,
};
