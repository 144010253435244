import Image from 'next/image';
import FlagSVG from './tab.svg';
import { customImageLoader } from '../NextImage';
import { NextLink } from '../NextLink';

interface IFlag {
  url: string;
  locale?: string;
}

const Flag = ({ locale = 'en_GB', url }: IFlag) => {
  const ImageContainer = () => (
    <div className="cursor-pointer fixed top-[38px] right-[8px] z-21 -rotate-90 w-[51px] h-[30px] md:w-[61px] md:h-[36px] md:right-0 md:top-[200px] md:rotate-0 transform print:hidden">
      <Image src={FlagSVG} alt="Flag" loader={customImageLoader} />
    </div>
  );
  return (
    <>
      {locale === 'it_IT' ? (
        <a href="https://contatti.cameo.it/" rel="noreferrer" target="_blank">
          <ImageContainer />
        </a>
      ) : (
        <NextLink href={url}>
          <ImageContainer />
        </NextLink>
      )}
    </>
  );
};

export default Flag;
