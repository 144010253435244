import { StringService } from 'service/string/stringService';
import type { InternalLink } from 'types/link';
import { PathSegment, PathTranslations } from '../getPathTranslations';

interface GetLocalizedLinkItem extends InternalLink {
  url?: string;
}

interface GetLocalizedPathParams {
  item?: GetLocalizedLinkItem;
  pathTranslations: PathTranslations;
}

export const getLocalizedLinkPath = ({
  item,
  pathTranslations,
}: GetLocalizedPathParams): string => {
  let linkUrl;

  if (!item) return '';

  if (item?.internalLink && item?.internalLink?.__typename === 'GenericPage') {
    if (item.internalLink.type === 'Generic') {
      linkUrl = `${pathTranslations.genericPage}${item?.internalLink?.slug}`;
    } else if (item.internalLink.type) {
      linkUrl = `${
        pathTranslations[StringService.lowerCaseFirstLetter(item.internalLink.type) as PathSegment]
      }${
        item.internalLink.subPath ? StringService.removeLeadingSlash(item.internalLink.subPath) : ''
      }${item.internalLink.slug}`;
    }
  } else if (item?.internalLink && item?.internalLink?.__typename === 'ProductSubcategoryPage') {
    const categorySlug =
      item?.internalLink?.productCategoryPage?.slug ||
      item?.internalLink?.productSubcategoryPage?.productCategoryPage?.slug;
    const subcategorySlug = item?.internalLink?.productSubcategoryPage?.slug;
    linkUrl = `${pathTranslations.productsSubCategory}${categorySlug ? `${categorySlug}/` : ''}${
      subcategorySlug ? `${subcategorySlug}/` : ''
    }${item?.internalLink.slug}`;
  } else if (item?.internalLink && item?.internalLink.__typename === 'ProductCategoryPage') {
    linkUrl = `${pathTranslations.productsCategory}${item?.internalLink.slug}`;
  } else if (item?.internalLink && item?.internalLink.__typename === 'RecipeLandingPage') {
    linkUrl = `${pathTranslations.recipes}${item?.internalLink.slug}`;
  } else if (item?.internalLink && item?.internalLink.__typename === 'ProductLandingPage') {
    linkUrl = `${pathTranslations.products}${item?.internalLink.slug}`;
  } else if (item?.internalLink && item?.internalLink.__typename === 'RecipeCategory') {
    linkUrl = `${pathTranslations.recipesCategory}${item?.internalLink.slug}`;
  } else if (item?.internalLink && item?.internalLink.__typename === 'RecipeSubcategory') {
    const categorySlug =
      item?.internalLink?.recipeCategory?.slug ||
      item?.internalLink?.recipeSubcategory?.recipeCategory?.slug;
    const subcategorySlug = item?.internalLink?.recipeSubcategory?.slug;
    linkUrl = `${pathTranslations.recipesSubCategory}${categorySlug ? `${categorySlug}/` : ''}${
      subcategorySlug ? `${subcategorySlug}/` : ''
    }${item?.internalLink.slug}`;
  } else {
    linkUrl = item?.url;
  }

  return linkUrl ?? '';
};
