import type { LocalizedDomain } from 'types/LocalizedDomain';

export const getDomainConfigByLocale = (
  locale: string,
  localizedDomains: Array<LocalizedDomain> | [],
) =>
  localizedDomains?.find(
    (localizedDomain: LocalizedDomain) =>
      localizedDomain?.defaultLocale === locale ||
      localizedDomain?.locales?.find((localizedDomainLocale) => localizedDomainLocale === locale),
  );
