import { Text as Paragraph, Title } from '../../Typography';
import { NextLink } from '../../NextLink';
import { PlainButton } from '../../PlainButton';
import { INotificationBannerProps } from '../index';

export const MobileContent = ({
  title,
  description,
  link,
}: Pick<INotificationBannerProps, 'title' | 'description' | 'link'>) => {
  return (
    <div className="md:hidden text-left w-full items-center justify-items-center">
      <Title className="mb-3 px-5 ml-3 md:ml-0" tag="h3" type="xxxs">
        {title}
      </Title>
      <Paragraph tag="p" type="md">
        {description}
      </Paragraph>
      <NextLink
        href={link?.url}
        className="py-4 flex items-center justify-start relative"
        rel="noreferrer"
        target={link?.openInNewTab ? '_blank' : '_self'}
      >
        <PlainButton label={link?.displayName} type="lead" className="text-white" />
      </NextLink>
    </div>
  );
};
