// @ts-nocheck
import React from 'react';
// Component
import { LiquidButton } from 'components/LiquidButton';
import { NextLink } from 'components/NextLink';
import { NoResult } from 'components/SearchOverlay/NoResult';
import { SearchResults } from 'components/SearchOverlay/Results';
import { Configure, InstantSearch, SearchBox, useHits } from 'react-instantsearch-hooks-web';
// Types

import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import { TrackingService } from 'service/tracking/trackingService';
import { COLORS } from 'styles/constants';
import type { ISearchOverlayTitles } from 'types/SearchOverlay';

const SearchHits = ({
  setHitsNumber,
  searchBoxRef,
  searchData = {},
  searchValue,
  onClick,
  ...props
}: {
  setHitsNumber?: (nHits: number) => void;
  searchBoxRef: any;
  searcValue?: string;
  searchData?: ISearchOverlayTitles;
  onClick?: () => void;
}) => {
  const { pathTranslations } = useGlobalContext();
  const searchUrl = pathTranslations?.search;
  const { hits, results } = useHits(props);
  if (setHitsNumber) {
    setHitsNumber(hits.length);
  }

  return (
    <>
      {results?.query !== '' ? (
        <div className="ais-SearchResults" data-test="hits-container">
          {hits.map((hit: any, index) => (
            <SearchResults
              key={`${hit.id || hit.objectID}-${index}`}
              hits={hit}
              searchValue={results?.query || ''}
              copy={searchData?.copy}
              onClick={onClick}
            />
          ))}
          {hits.length && (
            <div data-test="card-carousel-cta-button-wrapper" className="text-center">
              <NextLink
                href={`${searchUrl || '/search'}?query=${results?.query}`}
                onClick={() => {
                  TrackingService.clickSearchAllSuggestions &&
                    TrackingService.clickSearchAllSuggestions(searchBoxRef, hits.length);
                  onClick && onClick();
                }}
                target="_self"
                rel="noreferrer"
              >
                <LiquidButton
                  data-test="card-carousel-cta-button"
                  text={`${searchData?.linkText} ${hits.length > 4 ? `(${hits.length})` : ''}`}
                  height={58}
                  width={250}
                  color={COLORS.primary}
                  textColor={COLORS.white}
                  isWidthAuto
                />
              </NextLink>
            </div>
          )}
        </div>
      ) : (
        searchValue && (
          <NoResult
            data={searchData}
            searchBoxData={searchValue}
            trackingEvents={{
              trackNoResultsEvent: TrackingService.trackNoResultsEvent,
              trackResetSearchBoxEvent: TrackingService.trackResetSearchBoxEvent,
            }}
          />
        )
      )}
    </>
  );
};

const CustomSearch = React.forwardRef(
  (
    {
      searchData = {},
      handleSubmit,
      setHitsNumber,
      hitsNumber = -1,
      searchValue,
      setSearchValue,
      setShow,
      searchSubmitHandler,
      autoFocus = true,
      onClick,
    }: {
      searchData?: ISearchOverlayTitles;
      handleSubmit?: () => void;
      setHitsNumber?: (nHits: number) => void;
      hitsNumber?: number;
      searchValue?: string;
      setSearchValue?: (searchValue: string) => void;
      setShow?: (isShow: boolean) => void;
      show?: boolean;
      searchSubmitHandler?: (searchValue: string) => void;
      setSearchQuery?: (searchQuery: string) => void;
      autoFocus?: boolean;
      onClick?: () => void;
    },
    parentRef: any,
  ) => {
    const handleChange = (event: any) => {
      event.preventDefault();
      TrackingService.searchSubmit(parentRef?.current?.value, hitsNumber);
      if (handleSubmit) {
        handleSubmit();
      }
      if (searchSubmitHandler) {
        searchSubmitHandler(event.target[0].value);
      }
    };

    let timerId: any;
    const timeout = 300;
    const queryHook = (query: any, search: (arg0: any) => void) => {
      if (timerId) {
        clearTimeout(timerId);
      }
      // need this setTimeour for debounce pupose
      timerId = setTimeout(() => search(query), timeout);
      if (setShow) {
        setShow(query.length > 0);
      }
      if (setSearchValue) {
        setSearchValue(query);
      }
    };
    return (
      <InstantSearch
        searchClient={searchData.searchClient}
        indexName={searchData?.searchIndexName || ''}
        stalledSearchDelay={200}
      >
        <Configure hitsPerPage={6} distinct />
        <SearchBox
          onSubmit={(event) => handleChange(event)}
          queryHook={queryHook}
          ref={parentRef}
          autoFocus={autoFocus}
        />
        <SearchHits
          setHitsNumber={setHitsNumber}
          searchBoxRef={parentRef}
          searchData={searchData}
          searchValue={searchValue}
          onClick={onClick}
        />
      </InstantSearch>
    );
  },
);

export default CustomSearch;
