import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Text as Paragraph } from '../Typography';

export interface IPlainButtonProps {
  id?: string;
  icon?: ReactNode;
  label?: string;
  type?: any;
  buttonType?: 'button' | 'reset' | 'submit' | undefined;
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}

export const PlainButton = ({
  id = 'plain-button',
  icon,
  label,
  buttonType = 'button',
  type,
  disabled = false,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className,
}: IPlainButtonProps) => (
  <button
    id={id}
    className={clsx(
      'bg-white bg-opacity-0 border h-button rounded-full px-7 flex flex-row items-center flex-nowrap hover:shadow-md disabled:shadow-none disabled:border-0 disabled:cursor-not-allowed disabled:bg-opacity-100 focus:border-2 active:border-2 active:bg-opacity-100 active:bg-white',
      className,
    )}
    type={buttonType}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    {icon && (
      <div
        className={clsx(
          'sm:pr-1 md:pr-2.5 flex',
          disabled ? 'text-green stroke-green' : 'text-black',
        )}
      >
        {icon}
      </div>
    )}
    <Paragraph
      tag="div"
      type={type}
      className={clsx(disabled ? 'text-green' : 'text-black', className)}
    >
      {label}
    </Paragraph>
  </button>
);
