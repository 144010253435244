import tw from 'tailwind-styled-components';
import styled from 'styled-components';
import { Title, Text as Paragraph } from '../Typography';

export const GridContent = tw.div`
  text-center
  md:mb-30
  sm:mb-50
  mt-58
  sm:hidden
  md:block
`;
export const SubHeading = tw(Paragraph)`
  mb-2.5
`;
export const Heading = tw(Title)`
  mb-5
  text-gray-300
`;
export const SearchOverlayWrapper = styled.div`
  z-index: 99;
  padding-bottom: 50px;
  .ais-Hits {
    margin-top: 50px;
  }
  .ais-SearchBox {
    position: relative;
  }
  .ais-SearchBox-input {
    width: 100%;
    border-radius: 50px;
    height: 90px;
    margin-top: 30px;
    padding: 0 60px;
    font-size: 1.75rem;
    border-color: #bbc4ba;
  }
  .ais-SearchBox-reset {
    display: none;
  }
  .ais-SearchBox-submit {
    position: absolute;
    top: 64px;
    right: 40px;
    padding: 5px;
    svg {
      width: 20px;
      height: 20px;
    }
  }
  .ais-SearchBox-loadingIndicator {
    position: absolute;
    right: 80px;
    top: 64px;
  }
  input.ais-SearchBox-input[type='search']::-webkit-search-decoration,
  input.ais-SearchBox-input[type='search']::-webkit-search-cancel-button,
  input.ais-SearchBox-input[type='search']::-webkit-search-results-button,
  input.ais-SearchBox-input[type='search']::-webkit-search-results-decoration {
    display: none;
  }
  .ais-Hits-list {
    padding: 0;
    margin: 0;
  }
  @media (max-width: 480px) {
    .ais-SearchBox-input {
      height: 60px;
      padding: 0 88px 0 20px;
      font-size: 1rem;
    }
    .ais-SearchBox-submit {
      top: 43px;
      right: 20px;
      z-index: 20;
    }
    .ais-SearchBox-loadingIndicator {
      top: 52px;
      right: 60px;
      svg {
        width: 15px;
        height: 15px;
      }
    }
  }
`;
export const SearchOverlayWrapperStyle =
  'absolute w-full h-full left-0 z-30 bg-white overflow-auto lg:top-header sm:top-60';

export const SearchInput = tw.input`
  w-full
  form-input
  rounded-58
  h-90
  mt-30
  text-2xxl
  px-60
`;

export const SearchResultWrapper = tw.div`
  mt-5
`;

export const Image = styled.div`
  position: relative;
  flex-basis: 120px;
  flex-grow: 0;
  flex-shrink: 0;
  @media (max-width: 480px) {
    flex-basis: 74px;
  }
`;
export const ImageStyle = 'h-68 mr-5 rounded-md overflow-hidden';
export const NoResultSection = tw.div`
  pt-60
`;
export const SearchButton = tw.button`
  bottom-0
  left-0
  right-0
  bg-white
  w-full
  search-button-js
  z-20
`;
export const SubHeadingText = styled.span`
  opacity: 70%;
`;
