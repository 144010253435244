import { LocalizedDomain } from 'types/LocalizedDomain';
import { LocalizationService } from '../localizationService';

export const getIsMultiLanguageDomain = (
  locale: string,
  localizedDomains: Array<LocalizedDomain> | [],
) => {
  const domainConfig = LocalizationService.getDomainConfigByLocale(locale, localizedDomains);

  return (domainConfig?.locales?.length && domainConfig?.locales?.length > 1) || false;
};
