import { useLayoutEffect, useState } from 'react';

const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState<number | null>(null);
  const screenSize = () => {
    setScreenWidth(window.innerWidth);
  };

  useLayoutEffect(() => {
    screenSize();
    window.addEventListener('load', screenSize);
    window.addEventListener('resize', screenSize);
    return () => window.removeEventListener('resize', screenSize);
  }, []);

  return screenWidth;
};

export default useScreenWidth;
