import { FC, PropsWithChildren } from 'react';
import themes from '@/themes/themes.json';
import type { PathTranslations } from 'service/localization/getPathTranslations';

import { getThemeConfig } from '@/styles/getThemeConfig';
import { GlobalContextProvider } from '@/components/Context/GlobalContext';
import RootLayoutClient, {
  RootLayoutClientProps,
} from '@/components/PageLevelComponents/ClientLayout';
import RootSupremeLayoutClient from '@/components/PageLevelComponents/__themes__/supreme/ClientLayout';
import { AVAILABLE_THEMES } from '@/styles/constants';
import dynamic from 'next/dynamic';

export interface LayoutProps extends PropsWithChildren<RootLayoutClientProps> {
  pathTranslations: PathTranslations;
  locale: string;
}

const Fonts = dynamic(() => import('@/components/Fonts'));

export const Layout: FC<LayoutProps> = ({
  children,
  themeName,
  locale,
  pathTranslations,
  ...rest
}) => {
  const getTheme = themeName as keyof typeof themes;
  const theme = getTheme ? themes[getTheme] : null;
  let Component = RootLayoutClient;

  if (themeName === AVAILABLE_THEMES.suprema) {
    Component = RootSupremeLayoutClient;
  }

  return (
    <GlobalContextProvider
      value={{
        theme: theme ?? undefined,
        themeName,
        pathTranslations,
      }}
    >
      {getThemeConfig(theme)}
      <Fonts locale={locale} theme={themeName} />
      <Component {...rest} locale={locale} themeName={themeName}>
        {children}
      </Component>
    </GlobalContextProvider>
  );
};
