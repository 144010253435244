import algoliasearch, { SearchClient } from 'algoliasearch';
import { Environment } from 'config/environment';

let searchClient: SearchClient;

const { NEXT_PUBLIC_ALGOLIA_APP_ID, NEXT_PUBLIC_ALGOLIA_API_KEY } =
  Environment.getBuildtimeEnvVars();

const getSearchClient = () => {
  if (!searchClient) {
    searchClient = algoliasearch(
      NEXT_PUBLIC_ALGOLIA_APP_ID as string,
      NEXT_PUBLIC_ALGOLIA_API_KEY as string,
    );
  }

  return searchClient;
};

export default getSearchClient;
