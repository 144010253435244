import { Grid, GridRow } from '../Grid';
import { Title, Text as Paragraph } from '../Typography';
import { SearchResultWrapper, Image, SubHeadingText, ImageStyle } from './styles';
import FadeInSection from '../FadeInSection';

import type { ImageType } from '../../types/ICardData';
import NextImage from 'components/NextImage';
import { StringService } from 'service/string/stringService';
import { useGlobalContext } from 'components/Context/hooks/useGlobalContext';
import type { PathSegment } from 'service/localization/getPathTranslations';
import { NextLink } from 'components/NextLink';

export const SearchResults = ({
  hits,
  searchValue,
  copy = {},
  onClick,
}: {
  hits: any;
  searchValue: string;
  copy?: any;
  onClick?: () => void;
}) => {
  const { pathTranslations } = useGlobalContext();
  const finalImageList = {
    desktop: '',
    mobile: '',
    tablet: '',
    hero: '',
  };
  hits.images?.forEach((imageDetail: ImageType) => {
    if (
      (imageDetail?.url && imageDetail?.url?.includes('.jpg')) ||
      (imageDetail?.url && imageDetail?.url?.includes('png'))
    ) {
      if (imageDetail?.width === 964) {
        finalImageList.desktop = imageDetail?.url;
      } else if (imageDetail?.width === 636) {
        finalImageList.tablet = imageDetail?.url;
      } else if (imageDetail?.width === 302 || imageDetail?.width === 320) {
        finalImageList.mobile = imageDetail?.url;
      } else if (imageDetail?.width === 1272 || imageDetail?.width === 1440) {
        finalImageList.hero = imageDetail?.url;
      } else {
        finalImageList.hero = imageDetail?.url;
      }
    } else if (imageDetail?.Url && imageDetail?.Url?.includes('.jpg')) {
      if (imageDetail?.Width === 964) {
        finalImageList.desktop = imageDetail?.Url;
      } else if (imageDetail?.Width === 636) {
        finalImageList.tablet = imageDetail?.Url;
      } else if (imageDetail?.Width === 302) {
        finalImageList.mobile = imageDetail?.Url;
      } else if (imageDetail?.Width === 1272) {
        finalImageList.hero = imageDetail?.Url;
      } else {
        finalImageList.hero = imageDetail?.Url;
      }
    }
  });
  const pageUrl = () => {
    if (hits.objectType === 'TIPS_TRICKS') {
      return `${pathTranslations?.tipAndTrick}${hits.slug || hits.id}`;
    }
    if (hits.objectType === 'PRODUCTS') {
      return `${pathTranslations?.product}${hits.slug || hits.id}`;
    }
    if (hits.objectType === 'RECIPES') {
      return `${pathTranslations?.recipe}${hits.slug || hits.id}`;
    }
    if (hits.objectType === 'ARTICLES') {
      if (hits.type) {
        if (hits?.type === 'generic') {
          return `${pathTranslations?.genericPage}${hits.slug || hits.id}`;
        }
        return `${
          pathTranslations?.[StringService.lowerCaseFirstLetter(hits.type) as PathSegment]
        }${hits.slug || hits.id}`;
      }
      return `${pathTranslations?.genericPage}${hits.slug || hits.id}`;
    }
    return undefined;
  };
  const dictionaryCategory = () => {
    if (hits.objectType === 'TIPS_TRICKS') {
      return copy?.searchCategoryTips || 'Tips & Tricks';
    }
    if (hits.objectType === 'PRODUCTS') {
      return copy?.searchCategoryProducts || 'Products';
    }
    if (hits.objectType === 'RECIPES') {
      return copy?.searchCategoryRecipes || 'Recipes';
    }
    if (hits.objectType === 'ARTICLES') {
      return copy?.searchCategoryArticles || 'Articles';
    }
    return false;
  };

  const searchTerm = searchValue;
  const searchTermRegex = new RegExp(
    // Escape special regex characters in the search term,
    // otherwise you will get an invalid regex
    `(${searchTerm.replace(/[.\\+*?[^\]$(){}=!<>|:-]/g, '\\$&')})`,
    'gi',
  );

  const getBoldFilteredText = (text: string, term: string) => {
    const substrings = text.split(searchTermRegex);
    return (
      <>
        {substrings.map((substring) =>
          substring.toLowerCase() === term.toLowerCase() ? <strong>{substring}</strong> : substring,
        )}
      </>
    );
  };

  return (
    <SearchResultWrapper data-test="search-results" className="search-results">
      <FadeInSection delay="150ms" fadeIn>
        {hits && (
          <div className="search-result-list-js">
            <Grid>
              <GridRow key={hits.id}>
                <NextLink
                  className="flex"
                  href={pageUrl() ?? ''}
                  onClick={onClick}
                  data-test="result-row"
                >
                  <Image className={ImageStyle}>
                    <NextImage
                      src={
                        finalImageList?.mobile ||
                        finalImageList?.tablet ||
                        finalImageList?.desktop ||
                        finalImageList?.hero
                      }
                      alt={hits?.title}
                      objectFit="contain"
                    />
                  </Image>
                  <div>
                    <Paragraph tag="p" type="leadxs" className="mb-2.5">
                      <SubHeadingText>
                        {dictionaryCategory()} {hits?.category && `/ ${hits?.category}`}
                      </SubHeadingText>
                    </Paragraph>
                    <Title tag="h2" type="xs" className="font-normal product-title">
                      {getBoldFilteredText(hits?.title, searchValue)}
                    </Title>
                  </div>
                </NextLink>
              </GridRow>
            </Grid>
          </div>
        )}
      </FadeInSection>
    </SearchResultWrapper>
  );
};
