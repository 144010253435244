import styled from 'styled-components';
import { COLORS } from 'styles/constants';
import tw from 'tailwind-styled-components';
import { Text as Paragraph } from '../../../Typography';

export const HeaderGrid = styled.div`
  box-shadow: 0px 1px 0px #bbc4ba;
`;

export const HeaderWrapper = tw.header`
  flex
  py-4
  items-center
`;

export const HeaderContainer = tw.div`
  w-2/5
  flex-grow
  flex
`;

export const HeaderLogoContainer = tw.div`
  w-1/5
  text-center
  print:hidden
`;

export const LogoContainer = styled.div`
  width: 78px;
  height: 52px;
`;

export const MainNav = tw.div`
  flex
`;

export const LinkContainer: any = styled(Paragraph as any)`
  margin-right: 30px;
  color: #292b29;
  &.selected {
    text-shadow: 1px 0px 0px #292b29;
    &:after {
      width: 98%;
      left: 1%;
    }
  }
  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 0;
    left: 0;
    bottom: -5px;
    border-bottom: 1px solid ${COLORS.primary};
    transition: all 0.5s;
  }

  &:hover {
    text-shadow: 1px 0px 0px #292b29;
    &:after {
      width: 98%;
    }
  }
`;

export const SearchBox = tw.div`
  flex
  justify-end
  items-center
  ml-6
`;

export const DrawerOverlay = tw.div`
  bg-black
  h-full
  fixed
  left-0
  w-full
  bg-opacity-75
  block
  transition-all
  duration-500
  ease-in-out
  drawer-overlay
  top-header
  focus:outline-none
  focus:border-red-300
  focus:ring
`;
export const SearchButton = styled.button`
  .search-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    &:after {
      content: '';
      position: absolute;
      display: block;
      width: 0;
      left: 0;
      bottom: 0;
      border-bottom: 1px solid ${COLORS.primary};
      transition: all 0.3s;
    }
  }
  &:hover {
    .search-label {
      color: #292b29;
      &:after {
        width: 98%;
      }
    }
    .search-icon {
      display: none;
    }
  }
`;
export const SearchAnimation = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  margin-right: 0;
  overflow: hidden;
  .arrow {
    opacity: 1;
    position: absolute;
    top: 50%;
    transform-origin: 50% 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  .arrow-first {
    transform: translate3d(60%, -50%, 0);
    animation: arrow-movement 0.3s ease-in-out;
    width: 16px;
  }
  .arrow-second {
    transform: translate3d(0%, -50%, 0);
    animation: arrow-movement-back 0.3s ease-in-out;
    width: 16px;
  }
  @keyframes arrow-movement {
    from {
      transform: translate3d(0%, -50%, 0);
    }
  }
  @keyframes arrow-movement-back {
    from {
      transform: translate3d(60%, -50%, 0);
    }
  }
`;

export const LanguageSelector = styled.div`
  display: flex;
  margin-left: 25px;
`;

export const LanguageButton = styled.a<{ $isActive: boolean }>`
  display: inline-block;
  padding: 8px 20px 6px;
  border: 1px solid #e8ebe8;
  border-radius: 50px;
  margin-left: 10px;
  transition: border-color 0.2s;
  :hover {
    border-color: #5a5e5a;
  }
  ${(p) => p.$isActive && 'border-color: #5A5E5A;'}
`;
