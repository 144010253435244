export type Theme = Record<string, string>;

export const getThemeConfig = (theme?: Theme | null) =>
  theme ? (
    <style jsx global>{`
      :root {
        ${Object.entries(theme)
          .map(([key, value]) => `--${key}: ${value};`)
          .join('\n')}
      }
    `}</style>
  ) : null;
