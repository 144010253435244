import { LocalizedDomain } from 'types/LocalizedDomain';

/**
 * searches through LOCALIZED_DOMAINS to either match the default locale or a locale in the available locales array and returns its config
 * @param locale
 * @param localizedDomains
 * @returns domain config
 */
export const getDomainConfigByHost = (host: string, localizedDomains: LocalizedDomain[]) =>
  localizedDomains.find(
    (localizedDomain: LocalizedDomain) =>
      `${localizedDomain.domain}${localizedDomain.domain.includes('localhost') ? ':3000' : ''}` ===
      host,
  ) ?? localizedDomains[0];
