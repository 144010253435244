/* eslint-disable @typescript-eslint/no-unused-expressions */
import { useState, useEffect } from 'react';
import { Grid, GridItem, GridRow } from '../Grid';
import { Title } from '../Typography';
import { NoResultSection } from './styles';
import type { ISearchOverlayTitles } from './types';
import { NextLink } from 'components/NextLink';

export const NoResult = ({
  data,
  searchBoxData = '',
  trackingEvents,
}: {
  data: ISearchOverlayTitles;
  searchBoxData?: string;
  trackingEvents?: {
    trackNoResultsEvent?: (searchBoxData: string) => void;
    trackResetSearchBoxEvent?: (searchBoxData: string) => void;
  };
}) => {
  const [resetSearch, getResetSearch] = useState<any>();
  const [hitWrapper, getHitWrapper] = useState<any>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    trackingEvents?.trackNoResultsEvent && trackingEvents?.trackNoResultsEvent(searchBoxData || '');
  }, []);

  useEffect(() => {
    const resetButton = document.querySelector('.ais-SearchBox-reset') as HTMLElement;
    getResetSearch(resetButton);
    const hitConatiner = document.querySelector('.ais-Hits') as HTMLElement;
    getHitWrapper(hitConatiner);
  });

  const resetSearchBox = () => {
    resetSearch.click();
    hitWrapper?.classList?.add('hidden');
    trackingEvents?.trackResetSearchBoxEvent &&
      trackingEvents?.trackResetSearchBoxEvent(searchBoxData || '');
  };

  return (
    <NoResultSection>
      <Grid>
        <GridRow columns={16}>
          <GridItem colSpan={10} colStart={4} className="text-center">
            <Title tag="h4" type="lg" className="pb-5">
              {data?.copy?.notFoundTitle}
            </Title>
            <Title tag="p" type="xxxs">
              {data?.copy?.notFoundSubtitle} &lsquo;{searchBoxData}&rsquo;
            </Title>
            <Title tag="div" type="xxxs" className="pb-8 text-green pointer">
              <button type="button" onClick={() => resetSearchBox()}>
                {data?.copy?.tryNewText}
              </button>
            </Title>
            <Title tag="p" type="xxxs">
              {data?.copy?.notFoundDescription}
            </Title>
            <Title tag="div" type="xxxs" className="text-green">
              <NextLink href={data?.copy?.linkUrl || ''}>{data?.copy?.contactUsText}</NextLink>
            </Title>
          </GridItem>
        </GridRow>
      </Grid>
    </NoResultSection>
  );
};
