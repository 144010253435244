/**
 * function that checks the first character and removes it if it's a slash
 * @param string
 * @returns string with the leading slash removed
 */
export const removeLeadingSlash = (string: string): string => {
  if (string.charAt(0) === '/') {
    return string.substring(1);
  }
  return string;
};
