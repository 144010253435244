import React from 'react';
import { type Theme } from '../../styles/getThemeConfig';
import { type PathTranslations } from 'service/localization/getPathTranslations';

export const GlobalContext = React.createContext<{
  theme: Theme | undefined;
  themeName?: string;
  pathTranslations: PathTranslations;
}>({
  theme: undefined,
  themeName: '',
  pathTranslations: {} as PathTranslations,
});

export const GlobalContextProvider = GlobalContext.Provider;
export const GlobalContextConsumer = GlobalContext.Consumer;
