import { useEffect, useState } from 'react';

/**
 * @description Get Device type (Ipad)
 * @return {isIpad} true or false
 */
const useIsIpad = () => {
  const [isIpad, setIsIpad] = useState<boolean>(false);
  useEffect(() => {
    const device = window.navigator.userAgent.toLocaleLowerCase();
    if (device.indexOf('ipad') > -1) {
      setIsIpad(true);
    }
  }, []);
  return isIpad;
};

export default useIsIpad;
