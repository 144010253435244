import React from 'react';
import tw from 'tailwind-styled-components';

const Container = tw.div``;

export const TWContainer = ({
  children,
  twClasses = '',
}: {
  children: React.ReactNode;
  twClasses?: string;
}) => (
  <Container className={twClasses} data-test="ingredient-container">
    {children}
  </Container>
);
