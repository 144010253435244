import pathTranslations from 'config/pathTranslations.json';
import { LocalizedDomain } from 'types/LocalizedDomain';
import { getIsMultiLanguageDomain } from '../getIsMultiLanguageDomain';

type PathTranslationsKeys = keyof typeof pathTranslations;

/**
 * get translation of a specfic url segment by locale using pathTranslations.json
 * getPathSegmentTranslation('de_DE', 'recipes') // 'rezepte'
 * getPathSegmentTranslation('de_DE', 'products') // 'produkte'
 * if there is no translation for an url segment, it returns pathSegment
 * @param locale string
 * @param pathSegment PathTranslationsKeys
 * @returns string
 */
const getPathSegmentTranslation = (locale: string, pathSegment: PathTranslationsKeys) => {
  const pathSegmentTranslation = pathTranslations?.[pathSegment] as {
    locales: string[];
    translation: string;
  }[];
  return (
    pathSegmentTranslation.find((sectionTranslation) =>
      sectionTranslation.locales.find((translationLocale) => translationLocale === locale),
    )?.translation ?? pathSegment
  );
};

const availablePathSegments = [
  'recipe',
  'recipes',
  'recipesCategory',
  'recipesSubCategory',
  'product',
  'products',
  'productsCategory',
  'productsSubCategory',
  'tipAndTrick',
  'tipsAndTricks',
  'search',
  'genericPage',
  'inspiration',
  'services',
  'support',
  'about',
  'productPromotion',
] as const;

export type PathSegment = (typeof availablePathSegments)[number];

export type PathTranslations = Record<PathSegment, string>;

/**
 * get a translated path by locale resolved to our next pages structure
 * getPathTranslation('de_DE', 'recipe') // 'rezepte/r'
 * getPathTranslation('de_DE', 'recipes') // 'rezepte'
 * if rewrites change, make sure to also adjust them here (TODO: make sure to use one source, where rewrite sources are defined)
 * @param locale string
 * @param pageType PageType
 * @returns string
 */
const getPathTranslation = (locale: string, pathSegment: PathSegment) => {
  switch (pathSegment) {
    case 'about':
      return getPathSegmentTranslation(locale, 'about');
    case 'search':
      return getPathSegmentTranslation(locale, 'search');
    case 'support':
      return getPathSegmentTranslation(locale, 'support');
    case 'services':
      return getPathSegmentTranslation(locale, 'services');
    case 'genericPage':
      return '';
    case 'inspiration':
      return getPathSegmentTranslation(locale, 'inspiration');
    case 'tipAndTrick':
      return `${getPathSegmentTranslation(locale, 'inspiration')}/${getPathSegmentTranslation(
        locale,
        'tips-and-tricks',
      )}/t`;
    case 'tipsAndTricks':
      return `${getPathSegmentTranslation(locale, 'inspiration')}/${getPathSegmentTranslation(
        locale,
        'tips-and-tricks',
      )}`;
    case 'product':
      return `${getPathSegmentTranslation(locale, 'products')}/p`;
    case 'products':
      return getPathSegmentTranslation(locale, 'products');
    case 'productsCategory':
      return `${getPathSegmentTranslation(locale, 'products')}/c`;
    case 'productsSubCategory':
      return `${getPathSegmentTranslation(locale, 'products')}/s`;
    case 'productPromotion':
      return `${getPathSegmentTranslation(locale, 'products')}/${getPathSegmentTranslation(
        locale,
        'promotions',
      )}`;
    case 'recipe':
      return `${getPathSegmentTranslation(locale, 'recipes')}/r`;
    case 'recipes':
      return getPathSegmentTranslation(locale, 'recipes');
    case 'recipesCategory':
      return `${getPathSegmentTranslation(locale, 'recipes')}/c`;
    case 'recipesSubCategory':
      return `${getPathSegmentTranslation(locale, 'recipes')}/s`;
    default:
      return '';
  }
};

/**
 * get all path translations for the current locale
 * @param locale string
 * @param localizedDomains LocalizedDomain[]
 * @param addDomainLocale boolean - if true, the domain locale will be added to the path, if on multi language domain.
 * Only set to true, if NOT used for a next/link, as locale will be added automatically to href by next/link
 * e.g. de_DE:
 * @returns {
 * recipe: '/rezepte/r/',
 * recipes: '/rezepte/',
 * recipesCategory: '/rezepte/c/',
 * recipesSubCategory: '/rezepte/s/',
 * product: '/produkte/p/',
 * products: '/produkte/',
 * productsCategory: '/produkte/c/',
 * productsSubCategory: '/produkte/s/',
 * tipAndTrick: '/tipps-tricks/t/',
 * tipsAndTricks: '/tipps-tricks/',
 * search: '/search/',
 * genericPage: '/',
 * inspiration: '/inspiration/',
 * services: '/services/',
 * support: '/hilfe/',
 * about: '/ueber-dr-oetker/',
 * productPromotion: '/produkte/promotions/'
 * }
 */
export const getPathTranslations = (
  locale: string,
  localizedDomains: LocalizedDomain[],
  addDomainLocale = false,
): PathTranslations => {
  const pathTranslations = availablePathSegments.reduce((translations, pathSegment) => {
    const path = getPathTranslation(locale, pathSegment);
    const isMultiLanguageDomain = getIsMultiLanguageDomain(locale, localizedDomains);
    const translatedPath =
      isMultiLanguageDomain && addDomainLocale ? `/${locale}/${path}/` : `/${path}/`;

    return { ...translations, [pathSegment]: translatedPath.replace('//', '/') };
  }, {});

  return pathTranslations as PathTranslations;
};
